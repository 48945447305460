/* Default width for all screen sizes */
.css-13r4j3i .MuiTextField-root {
    width: 25ch !important;
}
/* Media query for screens with a minimum width of md (medium) */
@media (min-width: 768px) {
    .css-13r4j3i .MuiTextField-root {
        width: 45ch !important;
        padding-right: 20px !important;
    }
}

.css-1pn6f0p-MuiButtonBase-root-MuiButton-root{
    color: #262627 !important;
    border: 1px solid rgba(0, 0, 0, 0.5) !important;
}

.css-qfz70r-MuiFormGroup-root{
    display: none !important;
}

.css-7monis-MuiButtonBase-root-MuiButton-root{
    color: #262627 !important;
    border: 1px solid rgba(0, 0, 0, 0.5) !important;
}

.css-1jfqqkn-MuiButtonBase-root-MuiChip-root:hover{
    background-color: rgb(0, 0, 0) !important;
}

.css-1rwt2y5-MuiButtonBase-root-MuiButton-root{
    padding: 3px 6px !important;
    text-transform: none !important;
    font-size: 0.78rem !important;
}