* {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: red white; /* For Firefox */
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 3px; /* Adjust the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: red; /* Adjust the color of the thumb of the scrollbar */
  border-radius: 5px; /* Adjust the border radius of the thumb */
  width: 5px; /* Adjust the width of the thumb */
}

.filter-sec-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgb(161, 161, 161) white; /* For Firefox */
}

.footer-quick-links ul li {
  cursor: pointer;
  display: "flex";
  justify-content: center;
  align-items: center;
}
.youtube-icon-container:hover {
  transform: scale(1.2); /* You can adjust the scale factor as needed */
}

.post-property:hover span {
  color: white;
}

.post-property:hover {
  border-color: white;
  outline: none;
}

.post-property::before,
.post-property::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.post-property::before {
  content: "";
  background: #000;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.post-property:hover::before {
  transform: translate3d(100%, 0, 0);
}

.btn {
  display: inline-block;
  padding: 0.9rem 1.8rem;
  font-size: 16px;
  font-weight: 700;
  color: white;
  border: 3px solid rgb(252, 70, 100);
  cursor: pointer;
  position: relative;
  background-color: transparent;
  text-decoration: none;
  overflow: hidden;
  z-index: 1;
  font-family: inherit;
}
.border h6 {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
}
.border h6:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #111;
}
.border h6:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 255px;
  background-color: #333;
}

/* below the css for custom loader  */
.loadingspinner {
  --square: 26px;
  --offset: 30px;
  --duration: 2.4s;
  --delay: 0.2s;
  --timing-function: ease-in-out;
  --in-duration: 0.4s;
  --in-delay: 0.1s;
  --in-timing-function: ease-out;
  width: calc(3 * var(--offset) + var(--square));
  height: calc(2 * var(--offset) + var(--square));
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  position: relative;
}

.loadingspinner div {
  display: inline-block;
  background: red;
  /*background: var(--text-color);*/
  /*box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);*/
  border: none;
  border-radius: 2px;
  width: var(--square);
  height: var(--square);
  position: absolute;
  padding: 0px;
  margin: 0px;
  font-size: 6pt;
  color: black;
}

.loadingspinner #square1 {
  left: calc(0 * var(--offset));
  top: calc(0 * var(--offset));
  animation: square1 var(--duration) var(--delay) var(--timing-function)
      infinite,
    squarefadein var(--in-duration) calc(1 * var(--in-delay))
      var(--in-timing-function) both;
}

.loadingspinner #square2 {
  left: calc(0 * var(--offset));
  top: calc(1 * var(--offset));
  animation: square2 var(--duration) var(--delay) var(--timing-function)
      infinite,
    squarefadein var(--in-duration) calc(1 * var(--in-delay))
      var(--in-timing-function) both;
}

.loadingspinner #square3 {
  left: calc(1 * var(--offset));
  top: calc(1 * var(--offset));
  animation: square3 var(--duration) var(--delay) var(--timing-function)
      infinite,
    squarefadein var(--in-duration) calc(2 * var(--in-delay))
      var(--in-timing-function) both;
}

.loadingspinner #square4 {
  left: calc(2 * var(--offset));
  top: calc(1 * var(--offset));
  animation: square4 var(--duration) var(--delay) var(--timing-function)
      infinite,
    squarefadein var(--in-duration) calc(3 * var(--in-delay))
      var(--in-timing-function) both;
}

.loadingspinner #square5 {
  left: calc(3 * var(--offset));
  top: calc(1 * var(--offset));
  animation: square5 var(--duration) var(--delay) var(--timing-function)
      infinite,
    squarefadein var(--in-duration) calc(4 * var(--in-delay))
      var(--in-timing-function) both;
}

@keyframes square1 {
  0% {
    left: calc(0 * var(--offset));
    top: calc(0 * var(--offset));
  }

  8.333% {
    left: calc(0 * var(--offset));
    top: calc(1 * var(--offset));
  }

  100% {
    left: calc(0 * var(--offset));
    top: calc(1 * var(--offset));
  }
}

@keyframes square2 {
  0% {
    left: calc(0 * var(--offset));
    top: calc(1 * var(--offset));
  }

  8.333% {
    left: calc(0 * var(--offset));
    top: calc(2 * var(--offset));
  }

  16.67% {
    left: calc(1 * var(--offset));
    top: calc(2 * var(--offset));
  }

  25.00% {
    left: calc(1 * var(--offset));
    top: calc(1 * var(--offset));
  }

  83.33% {
    left: calc(1 * var(--offset));
    top: calc(1 * var(--offset));
  }

  91.67% {
    left: calc(1 * var(--offset));
    top: calc(0 * var(--offset));
  }

  100% {
    left: calc(0 * var(--offset));
    top: calc(0 * var(--offset));
  }
}

@keyframes square3 {
  0%,
  100% {
    left: calc(1 * var(--offset));
    top: calc(1 * var(--offset));
  }

  16.67% {
    left: calc(1 * var(--offset));
    top: calc(1 * var(--offset));
  }

  25.00% {
    left: calc(1 * var(--offset));
    top: calc(0 * var(--offset));
  }

  33.33% {
    left: calc(2 * var(--offset));
    top: calc(0 * var(--offset));
  }

  41.67% {
    left: calc(2 * var(--offset));
    top: calc(1 * var(--offset));
  }

  66.67% {
    left: calc(2 * var(--offset));
    top: calc(1 * var(--offset));
  }

  75.00% {
    left: calc(2 * var(--offset));
    top: calc(2 * var(--offset));
  }

  83.33% {
    left: calc(1 * var(--offset));
    top: calc(2 * var(--offset));
  }

  91.67% {
    left: calc(1 * var(--offset));
    top: calc(1 * var(--offset));
  }
}

@keyframes square4 {
  0% {
    left: calc(2 * var(--offset));
    top: calc(1 * var(--offset));
  }

  33.33% {
    left: calc(2 * var(--offset));
    top: calc(1 * var(--offset));
  }

  41.67% {
    left: calc(2 * var(--offset));
    top: calc(2 * var(--offset));
  }

  50.00% {
    left: calc(3 * var(--offset));
    top: calc(2 * var(--offset));
  }

  58.33% {
    left: calc(3 * var(--offset));
    top: calc(1 * var(--offset));
  }

  100% {
    left: calc(3 * var(--offset));
    top: calc(1 * var(--offset));
  }
}

@keyframes square5 {
  0% {
    left: calc(3 * var(--offset));
    top: calc(1 * var(--offset));
  }

  50.00% {
    left: calc(3 * var(--offset));
    top: calc(1 * var(--offset));
  }

  58.33% {
    left: calc(3 * var(--offset));
    top: calc(0 * var(--offset));
  }

  66.67% {
    left: calc(2 * var(--offset));
    top: calc(0 * var(--offset));
  }

  75.00% {
    left: calc(2 * var(--offset));
    top: calc(1 * var(--offset));
  }

  100% {
    left: calc(2 * var(--offset));
    top: calc(1 * var(--offset));
  }
}

@keyframes squarefadein {
  0% {
    transform: scale(0.75);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

#furnishing > div {
  padding: 16px;
  box-shadow: 5px 5px 5px 5px #ddd;
  border-radius: 10px;
  text-align: center;
}

.radio-listingType {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7px 5px;
}
.radio-listingType:hover {
  background-color: rgba(255, 0, 0, 0.5);
  border-radius: 3px;
  color: red;
  font-weight: 400;
}
.sub-property-type {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 8px 5px;
  cursor: pointer;
}
.sub-property-type:hover {
  background-color: rgba(255, 0, 0, 0.5);
  border-radius: 3px;
  color: red;
  font-weight: 400;
}
.filter-sub-property-type-checkbox {
  margin-right: 7px;
  accent-color: red;
}
